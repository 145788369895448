import * as React from "react";

import {
    makeStyles,
    Dropdown,
    Option
} from "@fluentui/react-components";

import { Config, SignatureTag } from "../global.config"
import { getSignatureFieldString } from '../services/mergeService';

import Title from './Title';


interface SignatureTagsProps {
    config: Config;
    setToast: (message: string, type: string) => void;
}

const useStyles = makeStyles({
    dropdown: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        fontSize: '13px',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    fieldsContainer: {
        maxHeight: "calc(100vh - 340px)",
        overflowY: "auto",
        width: "20rem",
        backgroundColor: '#1E1E1E',
        border: "none",
        borderRadius: "4px"
      },
      fieldItem: {
        padding: ".7rem",
        cursor: "pointer",
        color: '#ffffffd4',
        borderBottom: "1px solid #ffffff21",
        "&:hover": {
          backgroundColor: "#252525",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
      apiName : {
        paddingLeft:".25rem",
        color: "gray"
      }
});

const SignatureTags: React.FC<SignatureTagsProps> = ({ config, setToast }) => {

    const styles = useStyles();

    const [selectedSigner, setSelectedSigner] = React.useState<string>(config.signers.length > 0 ? config.signers[0] : null);

    const handleInsertTag = async (tag: SignatureTag) => {

        try {

            await Word.run(async (context) => {

                const range = context.document.getSelection();
                range.load(["font", "style"]);

                await context.sync();
                range.insertOoxml(
                    getSignatureFieldString(`[${selectedSigner} ${tag.label}]`, `Sign.${tag.apiName}`),
                    Word.InsertLocation.replace
                );

                await context.sync();

                setToast('success', `Segment "${tag.label}" inserted`);
            });

        } catch (e) {
            setToast('error', JSON.stringify(e));
        }


	};

    const formatString = (template, ...values) =>
		template.replace(/{(\d+)}/g, (match, number) =>
		typeof values[number] !== 'undefined' ? values[number] : match
	);

    return (
        <div>

            <Title text="Add Signature Elements" variant="info"/>
            <Title text="Signer" variant="section"/>
            <Dropdown
                className={styles.dropdown}
                value={selectedSigner}
                onOptionSelect={(_event, option) => {
                console.log('onOptionSelect called', event, option)
                setSelectedSigner(option.optionValue);
                }}
            >
                {config?.signers.map((i) => (
                    <Option key={i} value={i} text={i}>
                        {i}
                    </Option>
                ))}
            </Dropdown>

            {config?.signerToTags[selectedSigner]?.length > 0 &&
                <div className={styles.fieldsContainer}>
                {config?.signerToTags[selectedSigner].map((tag) => (
                    <div key={tag.label}
                    onClick={() => handleInsertTag(tag)}
                    className={styles.fieldItem}
                    >
                        {tag.label} <span className={styles.apiName}>{tag.apiName}</span>
                    </div>
                ))}
            </div>}
        </div>
    );
};

export default SignatureTags;
