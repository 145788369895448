
export const getFieldCodes = async (): Promise<string[]> => {
    return await Word.run(async (context) => {
      const body = context.document.body;
      const fields = body.fields;
      fields.load("items");
      await context.sync();
      const fieldCodes = fields.items.map(field => field.code);
      return fieldCodes;
    });
  };

export const insertTag = async (tagText: string, _description: string, color: string) => {

    await Word.run(async (context) => {
        const range = context.document.getSelection();
        const contentControl = range.insertText(tagText, Word.InsertLocation.replace);
        contentControl.font.color = color;
        contentControl.font.highlightColor = "#fef7b2";
        contentControl.font.bold = true;
        await context.sync();
    });
}

export const getBase64Data = (): Promise<string> => {
    return new Promise((resolve, reject) => {

        getFile()
        .then(file=>{
            const slices = [];
            let slicesReceived = 0;

            const getSlice = (index: number) => {
                file.getSliceAsync(index, (sliceResult) => {
                if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
                    slices.push(sliceResult.value.data);
                    slicesReceived++;

                    if (slicesReceived === file.sliceCount) {
                    const fileContent = new Uint8Array(slices.flat());
                    const blob = new Blob([fileContent], { type: 'application/octet-stream' });
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const result = reader.result;
                        file.closeAsync();
                        if (typeof result === 'string') {
                            const base64data = result.split(',')[1];
                            resolve(base64data);
                        } else {
                            reject(new Error('FileReader result is not a string.'));
                        }
                    };
                    } else {
                        getSlice(slicesReceived);
                    }
                } else {
                    reject(new Error(sliceResult.error.message));
                }
                });
            };
            getSlice(0);
        })
    });
  };

  const getFile = (): Promise<Office.File> => {
    return new Promise((resolve, reject) => {
      Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 }, (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        } else {
          reject(new Error(result.error.message));
        }
      });
    });
};