import * as React from "react";

import {
    makeStyles,
    TabList,
    Tab
} from "@fluentui/react-components";

interface TabsProps {
    selectedTab: string;
    setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles({
    tab : {
        marginBottom : '12px'
    },
    tablist : {
        fontSize: '5px'
    }
});

const Tabs: React.FC<TabsProps> = ({ selectedTab, setSelectedTab }) => {

    const styles = useStyles();

    const handleTabChange = (_event: React.MouseEvent<HTMLElement>, data: { value: string }) => {
        setSelectedTab(data.value);
    };

    return (
        <TabList size="small" className={styles.tablist} selectedValue={selectedTab} onTabSelect={handleTabChange}>
            <Tab className={styles.tab} value="merge_fields">Merge</Tab>
            <Tab className={styles.tab} value="segments">Segments</Tab>
            <Tab className={styles.tab}value="signature">Sign Tags</Tab>
            <Tab className={styles.tab} value="publish">Publish</Tab>
        </TabList>
    );
};

export default Tabs;
