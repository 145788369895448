import * as React from "react";

import {
    makeStyles,
    Button
} from "@fluentui/react-components";

import { SalesforceSession } from "../global.config"
import { config } from "../env.config";

import Title from './Title';

interface LoginProps {
    setSession: React.Dispatch<React.SetStateAction<SalesforceSession>>;
    setToast: (message: string, type: string) => void;
}

const useStyles = makeStyles({
    loginButton : {
        marginTop: '6px',
        backgroundColor : '#0078D4',
        width: "20rem",
        '&:hover': {
          backgroundColor: '#005A9E', // Office blue color for focus border
        }
    }
});

const Tabs: React.FC<LoginProps> = ({ setSession, setToast }) => {

    const styles = useStyles();

    const initiateOAuth = () =>{

        const redirectUri = encodeURIComponent(config.SF_REDIRECT_URL);
        const scopes = encodeURIComponent('api web refresh_token offline_access');
        const authUrl = `${config.SF_LOGIN_URL}/services/oauth2/authorize?response_type=code&client_id=${config.SF_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scopes}&prompt=login`;

        Office.context.ui.displayDialogAsync(authUrl, { height: 60, width: 30 }, (result) => {
          const dialog = result.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any)=>{
            dialog.close();
            processOAuthResponse(arg);
          });
        });

    };

    const processOAuthResponse = (arg: any) =>{
        const message = arg.message;
        if (message.startsWith('error')) {
            setToast('error', message);
        } else {
          const data = JSON.parse(message);
          setSession({
            access_token : data?.access_token,
            refresh_token : data?.refresh_token,
            instance_url : data?.instance_url
          });
        }
    };

    return (
        <div>
            <Title text="Authenticate with Salesforce" variant="info"/>
            <Button
                className={styles.loginButton}
                onClick={initiateOAuth}>
                Login
            </Button>
        </div>
    );
};

export default Tabs;
