import * as React from "react";

import {
    MessageBar,
    MessageBarBody,
    makeStyles
} from "@fluentui/react-components";

import { ToastMessage } from "../global.config";

interface ToastProps {
    message:ToastMessage;
    onClick: () => void;
}

const useStyles = makeStyles({
    messageBarContainer: {
        display: "flex",
        width: '90%',
        zIndex : '10',
        position: 'absolute',
        flexDirection: "column",
        gap: "10px",
    }
});

const Toast: React.FC<ToastProps> = ({ message, onClick }) => {
    const styles = useStyles();

    if(!message?.text || !message?.type){
        return (<></>);
      }

      return (
        <div className={styles.messageBarContainer}>
          <MessageBar key={message?.type} intent={message?.type} onClick={onClick}>
            <MessageBarBody>
              {message?.text}
            </MessageBarBody>
          </MessageBar>
        </div>
      );
};

export default Toast;
