import * as React from "react";

import {
    makeStyles,
    Dropdown,
    Option,
    Input,
} from "@fluentui/react-components";

import Title from './Title';
import Button from './Button';

import { getSegmentFieldString } from '../services/mergeService';
import { getFieldCodes } from '../services/fileService';
import { Config } from "../global.config"

interface SegmentTagsProps {
    config: Config;
    setToast: (message: string, type: string) => void;
}

const useStyles = makeStyles({
    dropdown: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        fontSize: '13px',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    searchField: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    segmentsContainer: {
        maxHeight: "calc(100vh - 340px)",
        overflowY: "auto",
        width: "20rem",
        backgroundColor: '#1E1E1E',
        border: "none",
        borderRadius: "4px"
    },
    fieldItem: {
        padding: ".7rem",
        cursor: "pointer",
        color: '#ffffffd4',
        borderBottom: "1px solid #ffffff21",
        "&:hover": {
            backgroundColor: "#252525",
        },
        "&:last-child": {
            borderBottom: "none",
        },
    }
});



const SegmentTags: React.FC<SegmentTagsProps> = ({ config, setToast }) => {

    React.useEffect(() => {
		handleInit();
	}, []);

    const styles = useStyles();

    const [segmentName, setSegmentName] = React.useState<string>("");
    const [segments, setSegments] = React.useState<string[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const filteredSegments = segments
    ? segments.filter((field) =>
      field.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

    const handleInit = async () => {
        const fieldCodes = await getFieldCodes();
        let existingSegments = {};
        (fieldCodes || []).forEach((code) => {
            if(code.indexOf('SEGMENT:') >= 0){
                let name = code.split('SEGMENT:')[1].replace('START.','').replace('END.','')
                existingSegments[name] = true;
            }
        });
        setSegments(Object.keys(existingSegments).sort());
    }

    const handleExistingSegmentInsert = async (item) => {
        return handleInsertSegment(item)
    }

    const handleInsertNewSegment = async () => {
        if(!segmentName){
			setToast('error', 'You must enter a Segment Name');
			return;
		}
        return handleInsertSegment(segmentName);
    }

    const handleInsertSegment = async (itemName) => {

        const formattedName = itemName.replace(/\s+/g, '');

		await Word.run(async (context) => {

			const selection = context.document.getSelection();
			selection.load("text");

			await context.sync();
			selection.insertOoxml(getSegmentFieldString(config, `[${formattedName}]`, `SEGMENT:START.${formattedName}`), Word.InsertLocation.start);
			selection.insertOoxml(getSegmentFieldString(config, `[END:${formattedName}]`,`SEGMENT:END.${formattedName}`), Word.InsertLocation.end);
			await context.sync();

			setToast('success', `Segment "${formattedName}" inserted`);
			setSegmentName("");
            handleInit();

		});
	};

    return (
        <div>
            <Title text="Tag part of the document for conditional rendering." variant="info"/>
            <Title text="Segment Name" variant="section"/>

            <Input
                className={styles.searchField}
                type="text"
                placeholder="Enter a new segment name"
                value={segmentName}
                onChange={(event) => setSegmentName(event.target.value)}
            />

            <Button label="Insert New Segment" onClick={handleInsertNewSegment}/>

            <Title text={`Existing Segments (${filteredSegments.length})`}  variant="section"/>

            <Input className={styles.searchField}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}/>

            {
                filteredSegments?.length > 0 && <div className={styles.segmentsContainer}>
                { filteredSegments.map((s) => (
                    <div key={s} onClick={() => handleExistingSegmentInsert(s)} className={styles.fieldItem}>
                        {s}
                    </div>
                    ))
                }
                </div>
            }


        </div>
    );
};

export default SegmentTags;
