import * as React from "react";

import {
    makeStyles,
    Spinner
} from "@fluentui/react-components";

interface LoadingSpinnerProps {
    label: string;
}

const useStyles = makeStyles({
    spinnerContainer : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px'
      }
});

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ label }) => {
    const styles = useStyles();

    return (
        <div className={styles.spinnerContainer}>
            <Spinner label={label}/>
        </div>
    );
};

export default LoadingSpinner;
