import * as React from "react";

import {
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
    makeStyles
} from "@fluentui/react-components";

import { SettingsRegular, SettingsFilled } from "@fluentui/react-icons";

interface SettingsProps {
    onRefresh: () => void;
    onLogout: () => void;
}

const useStyles = makeStyles({
    container: {
        position: 'absolute',
        top : '24px',
        left: '16px'
    },
    icon: {
        fontSize: "18px"
    }
});

const Settings: React.FC<SettingsProps> = ({onRefresh,onLogout}) => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <SettingsRegular className={styles.icon}></SettingsRegular>
                </MenuTrigger>

                <MenuPopover>
                <MenuList>
                    <MenuItem onClick={onRefresh}>Refresh Configuration</MenuItem>
                    <MenuItem onClick={onLogout}>Logout</MenuItem>
                </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
};

export default Settings;
