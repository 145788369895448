import * as React from "react";

import {
    makeStyles,
    Button
} from "@fluentui/react-components";

interface ButtonProps {
    label: string;
    onClick: () => void;
}

const useStyles = makeStyles({
    submitButton: {
        backgroundColor : '#0078D4',
        width: "20rem",
        '&:hover': {
          backgroundColor: '#005A9E', // Office blue color for focus border
        }
    }
});

const CustomButton: React.FC<ButtonProps> = ({ label, onClick }) => {
    const styles = useStyles();

    return (
        <Button
          className={styles.submitButton}
          onClick={onClick}>
          {label}
        </Button>
    );
};

export default CustomButton;
