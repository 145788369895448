import * as React from "react";

import {
    makeStyles,
    Dropdown,
    Option
} from "@fluentui/react-components";

import { SalesforceSession ,Config, DocumentTemplate } from "../global.config"
import { uploadToS3 } from '../services/awsService';
import { getBase64Data } from '../services/fileService';
import { getConfiguration, createDocumentVersion, refreshToken } from '../services/salesforceService';

import LoadingSpinner from './LoadingSpinner';
import Title from './Title';
import TextArea from './TextArea';
import Button from './Button';

interface PublishProps {
    session: SalesforceSession;
    config: Config;
    setToast: (message: string, type: string) => void;
    setSession: (session: SalesforceSession, refresh: boolean) => void;
}

const useStyles = makeStyles({
    textArea : {
        marginBottom : '24px'
    },
    dropdown: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        fontSize: '13px',
        backgroundColor: '#1E1E1E',
        width: "20rem"
    }
});

const Publish: React.FC<PublishProps> = ({ session, config, setToast, setSession }) => {

    const styles = useStyles();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [templateKey, setTemplateKey] = React.useState<string>('');
    const [templateName, setTemplateName] = React.useState<string>('');
    const [versionNotes, setVersionNotes] = React.useState<string>('');
    const [versionUrl, setVersionUrl] = React.useState<string>('');

    const handleVersionNoteChange = (text:string) => {
        setVersionNotes(text);
    };

    const handleCreateVersion = async(s3Uri:string, session:SalesforceSession, retry:boolean = false) => {

        const body = {
            instance_url : session?.instance_url,
            access_token: session?.access_token,
            comments: versionNotes,
            template_key: templateKey,
            s3_uri : s3Uri
        };

        try {
            let res = await createDocumentVersion(body);
            return res;
        } catch (e){
            if(!retry && e == 'SESSION_EXPIRED'){
				let newSession = await refreshToken(session);
                if(!newSession){
					setToast('error', 'Session expired, please re-login');
				}
				await setSession(newSession, false);
				return handleCreateVersion(s3Uri, newSession, true);
			} else {
                throw e;
            }
        }
    }

    const handlePublishToCRM = async () => {

        if(!templateKey){
            setToast('error','Please selecte a Template');
            return;
        }

        try {

            await Office.onReady();

            setLoading(true);

            let base64data = await getBase64Data();

            //todo handle expired aws credentials here

            let s3Uri = await uploadToS3(base64data, templateKey, config.awsCredentials, config.awsRegion, config.s3Bucket);
            let res = await handleCreateVersion(s3Uri, session);
            setLoading(false);

            if(res?.error){
                setToast('error', res?.error);
                return;
            }

            setToast('success', `Version ${res?.versionName} submitted for review`);
            setVersionUrl(`${session.instance_url}/${res?.versionId}`);

        } catch (e) {

            setToast('error', e || 'Something failed');
            setLoading(false);

        }
    }

    if(loading){
        return (
            <LoadingSpinner label="Sending to CRM"/>
        );
    }

    return (
        <div>

            <Title text="Submit your changes to CRM for review" variant="info"/>
            <Title text="Template" variant="section"/>

            <Dropdown className={styles.dropdown}
            placeholder="Template"
            value={templateName}
            onOptionSelect={(_event, option) => {
            const object = config.templates.find((obj) => obj.key === option.optionValue);
            setTemplateName(object.label);
            setTemplateKey(object.key);
            }}>
                {config.templates.map((object) => (
                <Option key={object.key} value={object.key}>
                {object.label}
                </Option>
                ))}
            </Dropdown>

            <Title text="Version Notes" variant="section"/>
            <div className={styles.textArea}>
                <TextArea value={versionNotes} onChange={handleVersionNoteChange}/>
            </div>

            <Button label="Publish to CRM" onClick={handlePublishToCRM}/>
        </div>

      );
};

export default Publish;
