import { config } from "../env.config";
import { AWSCredentials } from "../global.config"

export const uploadToS3 = async (base64data: string, templateKey:string, awsCredentials:AWSCredentials , awsRegion:string, s3Bucket:string) => {

    let req = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            fileName : `templates/${templateKey}/${templateKey}-${new Date().getTime()}.docx`,
            fileContent: base64data,
            awsCredentials : awsCredentials,
            awsRegion : awsRegion,
            s3Bucket : s3Bucket
        })
    };

    try {

        let response = await fetch(`${config.API_ENDPOINT_URL}/uploadDocToS3`, req);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result?.data.Location;

    } catch (err){
        if (err instanceof Error) {
            throw new Error(`Fetch error: ${err.message}`);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};