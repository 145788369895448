import * as React from "react";

import {
    makeStyles,
    Dropdown,
    Option,
    Input,
} from "@fluentui/react-components";

import Title from './Title';

import {getMergeFieldString} from '../services/mergeService';

import { Config, MergeObject, MergeField } from "../global.config"

interface MergeFieldsProps {
    config: Config;
    setToast: (message: string, type: string) => void;
}

const useStyles = makeStyles({
    dropdown: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        fontSize: '13px',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    searchField: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    fieldsContainer: {
        maxHeight: "calc(100vh - 340px)",
        overflowY: "auto",
        width: "20rem",
        backgroundColor: '#1E1E1E',
        border: "none",
        borderRadius: "4px"
    },
    fieldItem: {
        padding: ".7rem",
        cursor: "pointer",
        color: '#ffffffd4',
        borderBottom: "1px solid #ffffff21",
        "&:hover": {
            backgroundColor: "#252525",
        },
        "&:last-child": {
            borderBottom: "none",
        },
    },
    fieldSubtitle : {
        fontSize: '11px',
        marginLeft : '4px',
        color: 'gray'
    }
});



const MergeFields: React.FC<MergeFieldsProps> = ({ config, setToast }) => {

    React.useEffect(() => {
        handleInit();
    }, [config]);

    const styles = useStyles();

    const [mergeObjects, setMergeObjects] = React.useState<MergeObject[]>([]);
    const [selectedObject, setSelectedObject] = React.useState<MergeObject>();
    const [selectedObjectKey, setSelectedObjectKey] = React.useState<string>("");
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const filteredFields = !selectedObject ? [] : selectedObject.fields.filter((field) =>
        field.label.toLowerCase().includes(searchTerm.toLowerCase()) || field.apiName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInit = () => {

        const allObjects:MergeObject = {
            label : 'All',
            apiName : 'All',
            fields: []
        }

        let objects:MergeObject[] = [allObjects];

        let mergeObjects = JSON.parse(JSON.stringify(config.mergeObjects));

        mergeObjects.forEach(obj=>{
            obj.fields.forEach(f=>{
                if(obj.apiName != 'VARIABLE'){
                    f.apiName = `${obj.apiName}.${f.apiName}`;
                }
            });
            obj.fields = obj.fields.sort((a, b) => a.label.localeCompare(b.label));
            objects.push(obj);
        });

        mergeObjects.forEach(o=>{
            let obj = JSON.parse(JSON.stringify(o));
            obj.fields.forEach(f=>{
                let field = JSON.parse(JSON.stringify(f));
                field.subtitle = obj.label;
                objects[0].fields.push(field);
            });
        });

        objects[0].fields = objects[0].fields.sort((a, b) => a.label.localeCompare(b.label));

        setMergeObjects(objects);
        setSelectedObject(objects[0]);
        setSelectedObjectKey('All');

    };

    const handleSearch = (text:string) => {
        setSearchTerm(text);
        let fields = !selectedObject ? [] : selectedObject.fields.filter((field) =>
          field.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    const insertMergeField = async (field: MergeField) => {
        await Word.run(async (context) => {
            const range = context.document.getSelection();
            range.load(["font", "style"]);

            await context.sync();

            const fieldType = field?.type ? field?.type.toUpperCase() : 'TEXT';
            const prefix = fieldType != 'TEXT' ? `${fieldType}:` : '';

            range.insertOoxml(
            getMergeFieldString(`{{${field.label}}}`, `${prefix}${field.apiName}`),
            Word.InsertLocation.replace
            );

            const insertedRange = range.getRange("Start").expandTo(range.getRange("End"));
            insertedRange.font.set(range.font);
            await context.sync();
        })
        .catch(function (error) {
            let errorMessage = JSON.stringify(error);
            if (error instanceof OfficeExtension.Error) {
                errorMessage = JSON.stringify(error.debugInfo);
            }
            setToast('error',errorMessage);
        });
    };

    return (
        <div>

            <Title text="Merge CRM data into your document" variant="info"/>
            <Title text="Category" variant="section"/>

            <Dropdown
                className={styles.dropdown}
                placeholder="Select a Category"
                value={selectedObjectKey}
                onOptionSelect={(_event, option) => {
                    const object = mergeObjects.find((obj) => obj.apiName === option.optionValue);
                    setSelectedObject(object);
                    setSelectedObjectKey(object?.label);
                }}
                >
                {mergeObjects.map((object) => (
                    <Option key={object.apiName} value={object.apiName}>
                        {object.label}
                    </Option>
                ))
                }
            </Dropdown>

            <Title text={`Fields (${filteredFields.length})`}  variant="section"/>

            <Input className={styles.searchField}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => handleSearch(event.target.value)}/>

            {
                filteredFields?.length > 0 && <div className={styles.fieldsContainer}>
                { filteredFields.map((field) => (
                    <div key={field.apiName} onClick={() => insertMergeField(field)} className={styles.fieldItem}>
                        {field.label} <span className={styles.fieldSubtitle}>{field.subtitle}</span>
                    </div>
                    ))
                }
                </div>
            }
        </div>
    );
};

export default MergeFields;
