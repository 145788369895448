import * as React from "react";
import { makeStyles, Dropdown, Input } from "@fluentui/react-components";

import { SalesforceSession, Config, ToastMessage, SignatureTag } from "./global.config"

import Logo from './components/Logo';
import Tabs from './components/Tabs';
import MergeFields from "./components/MergeFields";
import Publish from "./components/Publish";
import SignatureTags from './components/SignatureTags';
import SegmentTags from './components/SegmentTags';
import Login from "./components/Login";
import Toast from './components/Toast';
import Settings from './components/Settings';

import { getConfiguration, refreshToken, revokeToken } from './services/salesforceService';
import { setItem, getItem, clearStore } from './services/storageService';

interface AppProps {
  title: string;
}

const useStyles = makeStyles({
	root: {
		minHeight: "calc(100vh - 32px)",
		padding: "1rem",
		display: "flex",
		fontFamily: "Roobert",
		flexDirection: "column",
		alignItems: "center",
		backgroundColor: "#323232",
	}
});

const App: React.FC<AppProps> = (_props: AppProps) => {

	const styles = useStyles();
	const [selectedTab, setSelectedTab] = React.useState('merge_fields');
	const [message, setMessage] = React.useState<ToastMessage | null>(null);
	const timeoutRef = React.useRef(null);
	const [ session, setSession ] = React.useState<SalesforceSession>({ access_token: null, refresh_token : null, instance_url : null });
	const [ config, setConfig] = React.useState<Config>({
		segmentHighlightColor : '',
		segmentTextColor : '',
		segmentFont : '',
		segmentFontSize : 20,
		signers : [],
		templates : [],
		mergeObjects : [],
		signerToTags  :new Map<string, SignatureTag>(),
		awsCredentials : null,
		awsRegion : null,
		s3Bucket : null
	});

	React.useEffect(() => {
		handleInit();
	}, []);

	const handleInit = async () => {
		const session = await getItem('session');
		if (session) {
			setSession(session);
			const config = await getItem('config');
			if (config) {
				setConfig(config);
			}
		}
	};

	function loadFont() {
		const fontFace = `
			@font-face {
				font-family: 'Roobert';
				src: url('./assets/Roobert-Regular.otf') format('sans-serif');
				font-weight: normal;
				font-style: normal;
			}
		`;
		const style = document.createElement('style');
		style.innerHTML = fontFace;
		document.head.appendChild(style);
		document.body.style.fontFamily = 'Roobert, sans-serif';
	}

	// Load the custom font
	loadFont();

	const toast = (type, text) => {
		setMessage({
			text: text,
			type: type
		});
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			setMessage(null);
		}, 3000);
	}

	const handleCloseToast = () => {
		setMessage(null);
	}

	const handleSetSession = async (session: SalesforceSession, refresh:boolean = true) => {
		setSession(session);

		await setItem('session', session)

		if(!session.access_token){
			toast('error', 'Failed to connect to Salesforce, please contact support');
			return;
		}

		if(refresh){
			handleRefresh(session, false);
		}
	}

	const handleRefresh = async (session:SalesforceSession, showToast:boolean, retry:boolean=false) => {

		if(!session.access_token || !session.instance_url){
			toast('error', 'No salesforce session');
			return;
		}

		try {

			let conf = await getConfiguration(session);

			setConfig(conf);

			await setItem('config', conf);

			if(showToast){
				toast('success', 'Refresh completed');
			}

		} catch (e) {
			if(!retry && e == 'SESSION_EXPIRED'){
				let newSession = await refreshToken(session);
				if(!newSession){
					toast('error', 'Session expired, please re-login');
				}
				await handleSetSession(newSession, false);
				handleRefresh(newSession, showToast, true);
			} else {
				toast('error', e);
			}
		}
	}

	const handleManualRefresh = () => {
		handleRefresh(session, true);
	}

	const handleLogout = async () => {
		try {
			await revokeToken(session);
			clearStore();
			localStorage.clear();
			sessionStorage.clear();
		} catch (e){

		}
		setSession(null);
	}

	const handleSetToast = (type:string, text:string) => {
		toast(type, text);
	}

	return (
		<div className={styles.root}>

			<Toast message={message} onClick={handleCloseToast}/>

			{session?.access_token && (<Settings onRefresh={handleManualRefresh} onLogout={handleLogout}/>)}

			<Logo/>

			{!session?.access_token ? (
				<Login setSession={handleSetSession} setToast={handleSetToast}/>
			) : (
				<>
				<Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
				{selectedTab === 'merge_fields' && (<MergeFields config={config} setToast={handleSetToast}/>)}
				{selectedTab === 'signature' && (<SignatureTags config={config} setToast={handleSetToast}/>)}
				{selectedTab === 'publish' && (<Publish session={session} config={config} setToast={handleSetToast} setSession={handleSetSession}/>)}
				{selectedTab == 'segments' && (<SegmentTags config={config} setToast={handleSetToast}/>)}
				</>
			)}
		</div>
	);
};

export default App;
