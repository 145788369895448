import * as React from "react";

import {
    Image,
    makeStyles
} from "@fluentui/react-components";

const useStyles = makeStyles({
    title: {
        fontSize: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Roobert",
        marginBottom: "1rem",
    }
});

const Title: React.FC<{}> = () => {
    const styles = useStyles();

    return (
        <div className={styles.title}>
            <Image src="./assets/logo-filled.png" width={35} style={{marginRight:6}}></Image>DocGen
        </div>
    );
};

export default Title;
