import { openDB } from 'idb';

const DB_NAME = 'docgen-db';
const STORE_NAME = 'docgen-store';

const getDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
};

export const setItem = async (key: string, value: any) => {
  const db = await getDB();
  await db.put(STORE_NAME, value, key);
};

export const getItem = async (key: string) => {
  const db = await getDB();
  return db.get(STORE_NAME, key);
};

export const clearStore = async () => {
  const db = await getDB();
  await db.clear(STORE_NAME);
};