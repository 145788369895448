import * as React from "react";

import {
    makeStyles
} from "@fluentui/react-components";

interface TextAreaProps {
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles({
    textAreaContainer : {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    textArea : {
        width: '100%',
        height: '100px',
        padding: '10px',
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#1E1E1E',
        fontFamily: 'Roobert, sans-serif',
        border: 'none',
        borderRadius: '4px',
        boxSizing: 'border-box',
        resize: 'vertical',
        outline: 'none',
        caretColor: '#0078D4'
    }
});

const TextArea: React.FC<TextAreaProps> = ({ value, onChange }) => {
    const styles = useStyles();

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    return (
        <div className={styles.textAreaContainer}>
            <textarea className={styles.textArea} value={value} onChange={handleTextChange} />
        </div>
    );
};

export default TextArea;
