import * as React from "react";

import {
    makeStyles
} from "@fluentui/react-components";

interface TitleProps {
    text: string;
    variant: string;
}

const useStyles = makeStyles({
    section: {
        fontSize: '15px',
        marginBottom: '8px'
    },
    info : {
        fontSize: '12px',
        color: '#ffffff7d',
        textAlign: 'center'
    }
});

const Title: React.FC<TitleProps> = ({ text, variant }) => {
    const styles = useStyles();

    const variantStyle = variant == 'info' ? styles.info : styles.section;

    return (
        <p className={variantStyle}>{text}</p>
    );
};

export default Title;
